import moment from 'moment';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

/* provides a date and time live display */
function Clock(props) {
  const { format } = props;
  const [t, setTime] = useState(moment(new Date()));
  useEffect(() => {
    const id = setInterval(() => {
      setTime(moment(new Date()));
    }, 1000);
    return () => clearInterval(id);
  }, []);

  return (
    <div>
      {t.format(format)}
    </div>
  );
}

Clock.propTypes = {
  format: PropTypes.string.isRequired,
};

export default Clock;
