import React from 'react';
import PropTypes from 'prop-types';
import { LiveMessage } from 'react-aria-live';
import Departure from './departure';
import UseMBTAEventSource from './data';

function Departures(props) {
  const { station } = props;
  const queryUrl = `https://api-v3.mbta.com/predictions?api_key=${process.env.REACT_APP_MBTA_API_KEY}&include=stop,trip&filter[direction_id]=0&filter[route_type]=2&filter[stop]=${station.key}&sort=departure_time`;

  /* fetch data, will be updated */
  const departures = UseMBTAEventSource(queryUrl);

  /* compose text describing upcoming information */
  let departuresUpdateMessage = '';
  departuresUpdateMessage = `${departures.length} upcoming departures from ${station.name}.`;
  const upcomingAlerts = departures.filter(({ status }) => status && status !== 'On time');
  if (upcomingAlerts.length) {
    const alerts = upcomingAlerts.map(({
      trainNumber,
      destination,
      status,
      trackNumber,
    }) => `Train ${trainNumber} to ${destination} is ${status} ${trackNumber && `on track ${trackNumber}`}.`);

    departuresUpdateMessage += alerts.join();
  }

  return (
    <table className="departures-list">
      <thead>
        <tr>
          <th scope="col">Destination</th>
          <th scope="col">Train No.</th>
          <th scope="col">Departure Time</th>
          <th scope="col">Track No.</th>
          <th scope="col">Status</th>
        </tr>
      </thead>
      <tbody>
        {departures.length
          ? departures.map((d) => (
            <Departure
              key={d.key}
              destination={d.destination}
              trainNumber={d.trainNumber}
              routeName={d.routeName}
              departTime={d.departTime}
              trackNumber={d.trackNumber}
              status={d.status}
            />
          ))
          : <tr><td colSpan="5">No departures found. Try again later?</td></tr>}
      </tbody>
      <LiveMessage message={departuresUpdateMessage} aria-live="polite" />
    </table>
  );
}

Departures.propTypes = {
  station: PropTypes.shape({
    name: PropTypes.string,
    key: PropTypes.string,
  }).isRequired,
};

export default Departures;
