/* functions for handling data from the MBTA JSON API */
import moment from 'moment';
import { useState, useEffect } from 'react';

function UseMBTAEventSource(queryUrl) {
  const [rawData, setRawData] = useState([]);
  const [predictions, setPredictions] = useState([]);

  /* transform API response to objects for Departure components */
  const processData = (dataset) => {
    const trips = dataset.filter((result) => result.type === 'trip');
    const stops = dataset.filter((result) => result.type === 'stop');
    const hasTripAndStop = (relationships) => {
      const tripId = relationships.trip.data.id;
      const stopId = relationships.stop.data.id;
      const trip = trips.find((t) => t.id === tripId);
      const stop = stops.find((s) => s.id === stopId);
      return { trip, stop };
    };

    const predicts = dataset.filter((result) => result.type === 'prediction')
      .filter((result) => result.attributes.departure_time)
      .filter(({ relationships }) => {
        const { trip, stop } = hasTripAndStop(relationships);
        return trip && stop;
      });

    return predicts
      .sort(({ attributes: a }, { attributes: b }) => {
        const diff = moment(a.departure_time).diff(moment(b.departure_time));
        return diff;
      })
      .map((p, i) => {
        const { trip, stop } = hasTripAndStop(p.relationships);
        return {
          key: `${p.id}-${trip.id}-${stop.id}-${i}`,
          predictionId: p.id,
          tripId: trip.id,
          stopId: stop.id,
          destination: trip.attributes.headsign,
          trainNumber: trip.attributes.name,
          routeName: trip.relationships.route.data.id.replace('CR-', ''),
          departTime: moment(p.attributes.departure_time).format('h:mm a'),
          trackNumber: stop ? stop.attributes.platform_code : null,
          status: p.attributes.status,
        };
      });
  };

  const resetData = (queryResult) => {
    setRawData(JSON.parse(queryResult.data));
  };

  const updateData = (queryResult) => {
    const update = JSON.parse(queryResult.data);
    setRawData((data) => {
      const updatedData = [...data];
      const toUpdate = data.findIndex((d) => d.id === update.id);
      updatedData[toUpdate] = { ...data.find((d) => d.id === update.id), ...update };
      return updatedData;
    });
  };

  const addData = (queryResult) => {
    const toAdd = JSON.parse(queryResult.data);
    setRawData((rdata) => [...rdata, toAdd]);
  };

  const removeData = (queryResult) => {
    const toRemove = JSON.parse(queryResult.data);
    setRawData((rdata) => {
      const index = rdata.findIndex((d) => d.id === toRemove.id);
      return [
        ...rdata.slice(0, index),
        ...rdata.slice(index + 1),
      ];
    });
  };

  /* process incoming/altered data */
  useEffect(() => {
    if (!rawData) return;
    const data = processData(rawData);
    setPredictions(data);
  }, [rawData]);

  /* if the query changes, listen for events there instead */
  useEffect(() => {
    if (!queryUrl) return null;
    const evtSource = new EventSource(queryUrl, {
      accept: 'text/event-stream',
      'x-api-key': process.env.REACT_APP_MBTA_API_KEY,
    });
    evtSource.addEventListener('reset', resetData, false);
    evtSource.addEventListener('add', addData, false);
    evtSource.addEventListener('update', updateData, false);
    evtSource.addEventListener('remove', removeData, false);
    return function cleanup() { evtSource.close(); };
  }, [queryUrl]);

  return predictions;
}

export default UseMBTAEventSource;
