import React from 'react';
import PropTypes from 'prop-types';
import { LiveMessage } from 'react-aria-live';
import { CSSTransitionGroup } from 'react-transition-group';

function Departure(props) {
  const {
    destination,
    trainNumber,
    routeName,
    departTime,
    trackNumber,
    status,
  } = props;

  /* compose text describing upcoming information */
  let statusUpdateMessage = '';
  if (status && status !== 'On time') {
    statusUpdateMessage = `Train ${trainNumber} to ${destination} is ${status} ${trackNumber && `on track ${trackNumber}`}`;
  }

  return (
    <tr>
      <th scope="row">{destination}</th>
      <td>
        {trainNumber}
        {routeName ? <span className="show-on-mobile">{routeName}</span> : null}
      </td>
      <td>{departTime}</td>
      <td>
        {trackNumber ? <span className="show-on-mobile">Track</span> : null}
        {trackNumber}
      </td>
      <td>
        <CSSTransitionGroup
          transitionName="status-update"
          transitionEnterTimeout={500}
          transitionLeave={false}
        >
          <div>{status}</div>
        </CSSTransitionGroup>
      </td>
      <LiveMessage message={statusUpdateMessage} aria-live="polite" />
    </tr>
  );
}

Departure.defaultProps = {
  routeName: null,
  trackNumber: null,
  status: null,
};

Departure.propTypes = {
  destination: PropTypes.string.isRequired,
  routeName: PropTypes.string,
  trainNumber: PropTypes.string.isRequired,
  departTime: PropTypes.string.isRequired,
  trackNumber: PropTypes.string,
  status: PropTypes.string,
};

export default Departure;
