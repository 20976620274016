import React, { useState, useEffect } from 'react';
import { LiveAnnouncer } from 'react-aria-live';
import Clock from './clock';
import Departures from './departures';
import ErrorBoundary from './error';
import './App.scss';

function App() {
  const stationsListSupported = [
    { name: 'South Station', key: 'place-sstat' },
    { name: 'North Station', key: 'place-north' },
  ];

  const [station, setStation] = useState(stationsListSupported[0]);
  const [stationsList, setStationsList] = useState([]);

  /* populate <select> with list of CR stations */
  useEffect(() => {
    async function getStations() {
      const response = await fetch(`https://api-v3.mbta.com/stops?api_key=${process.env.REACT_APP_MBTA_API_KEY}&filter[route_type]=2&include=parent_station`);
      const data = await response.json();
      const stations = data.included
        .filter((s) => !['place-sstat', 'place-north'].includes(s.id))
        .map((s) => ({ name: s.attributes.name, key: s.id }))
        .sort(({ name: s1 }, { name: s2 }) => {
          const a = s1.toUpperCase();
          const b = s2.toUpperCase();
          if (a < b) { return -1; }
          if (a > b) { return 1; }
          return 0;
        });
      setStationsList(stations);
    }

    getStations();
  }, []);

  const changeStation = (e) => {
    const stationObj = stationsListSupported.find((s) => s.key === e.target.value);
    setStation(stationObj);
  };

  return (
    <div className="App">
      <header className="departures-header">
        <Clock format="MMM Do YYYY" />
        <div className="departure-stations">
          <select value={station.key} onChange={changeStation}>
            <optgroup label="Supported Stations">
              {stationsListSupported.map((s) => (
                <option key={s.key} value={s.key}>{s.name}</option>
              ))}
            </optgroup>
            <optgroup label="Other Stations" disabled>
              {stationsList.length && stationsList.map((s) => (
                <option key={s.key} value={s.key}>{s.name}</option>
              ))}
            </optgroup>
          </select>
        </div>
        <Clock format="h:mm:ss a" />
      </header>
      <main>
        <LiveAnnouncer>
          <ErrorBoundary>
            <Departures station={station} />
          </ErrorBoundary>
        </LiveAnnouncer>
      </main>
    </div>
  );
}

export default App;
